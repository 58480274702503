import * as React from "react";
import { graphql, Link } from "gatsby";

import { Layout } from "../components/layout";
import { Seo } from "../components/seo";

import * as classes from "./toilets.module.css";

const ToiletsPage = ({
  data: {
    allBorough: { nodes: boroughs },
  },
  location: { pathname },
}) => {
  return (
    <Layout>
      <Seo title="List of toilets" path={pathname} />
      <div className={classes.root}>
        <h1>List of toilets in Berlin</h1>
        <section className={classes.boroughsContainer}>
          <h2>Boroughs</h2>
          {boroughs.map(({ name }) => (
            <Link key={name} to={`/toilets/borough/${name}`}>
              {name}
            </Link>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ToiletsPageQuery {
    allBorough(sort: { fields: toiletCount, order: DESC }) {
      nodes {
        name
      }
    }
  }
`;

export default ToiletsPage;
